import Nav from "../Components/Nav";


const Menukort = () => {
  return (
    <div>
      <Nav />
    </div>
  );
};

export default Menukort;
