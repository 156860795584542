import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";
import { motion } from "framer-motion";

const Dessert = () => {
  return (
    <div>
      <Nav />
      <div className=" relative h-[100vh] gap-5 max-h-screen">
        <motion.p
          initial={{ opacity: 0, y: "-30" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-white text-center text-3xl Bebas mt-[13vh] border-b"
        >
          Dessert
        </motion.p>

        <motion.div className="mt-[2vh] mb-10 overflow-y-auto w-full flex flex-wrap justify-center items-center">
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Item
              img="/Assets/5-1.jpg"
              name="Hjemmelavet Romkugler"
              price="16,00 DKK"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Item
              img="/Assets/5-2.jpg"
              name="Kage"
              price="20,00+ DKK "
            />
          </motion.div>
          
        </motion.div>
      </div>
    </div>
  );
};

export default Dessert;
