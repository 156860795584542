import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";
import { motion } from "framer-motion";

const Specials = () => {
  return (
    <div>
      <Nav />
      <div className=" relative h-[100vh] gap-5 max-h-screen">
        <motion.p
          initial={{ opacity: 0, y: "-30" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-white text-center text-3xl Bebas mt-[13vh] border-b"
        >
          Specials
        </motion.p>

        <motion.div className="mt-[2vh] mb-10 overflow-y-auto w-full flex flex-wrap justify-center items-center">
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Item
              img="/Assets/4-1.jpg"
              name="Landgangsbrød"
              price="85,00 DKK"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Item
              img="/Assets/platte.png"
              name="Årstidens sæson platte"
              price="169,00 DKK"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <Item
              img="/Assets/4-3.jpg"
              name="Stjerneskud"
              desc="m. Stegt & dampet fisk"
              desc2="Bestilles senest 1 time før afhentning"
              price="119,00 DKK"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <Item
              img="/Assets/børneplatte.jfif"
              name="Børneplatte"
              price="119,00 DKK"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.5 }}
          >
            <Item
              img="/Assets/4-5.jpg"
              name="Salater"
              desc="Vegetar / Kylling-bacon / Tun-æg"
              desc2="Rejer-æg / Skinke"
              price="60,00 DKK"
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Specials;
