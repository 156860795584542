import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {

    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-[100vh] w-[100vw] bg-black flex flex-col justify-center text-center ">
          <span className="Bebas text-rose-600 text-[10vw]">Hovsa!</span> <br />
          <span className="text-white text-2xl ">
            Det ser ud til vi er stødt på en fejl! <br/>
            <span className="text-sm leading-[-10rem]">Prøv at genindlæse siden, hvis problemet bliver ved med at opstå kan du sende en mail til os hvor du forklare hvad du gjorde da problemet opstod og hvor</span>
          </span>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
