import { Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Menukort from "./Pages/Menukort";
import Preloader from "./Pages/Preloader";
import Smørrebrød from "./Pages/Smørrebrød";
import Sandwich from "./Pages/Sandwich";
import Countdown from "./Hooks/Countdown";
import Specials from "./Pages/Specials";
import Diverse from "./Pages/Diverse";
import Dessert from "./Pages/Dessert";
import Menuer from "./Pages/Menuer";
// import Nyheder from "./Pages/Nyheder";
import Bestilling from "./Pages/Bestilling";

function App() {
  return (
    <div className="App">
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/menukort" element={<Menukort />} />
        <Route exact path="/menukort/smørrebrød" element={<Smørrebrød />} />
        <Route exact path="/menukort/sandwich" element={<Sandwich />} />
        <Route exact path="/menukort/menuer" element={<Menuer />} />
        <Route exact path="/menukort/specials" element={<Specials />} />
        <Route exact path="/menukort/diverse" element={<Diverse />} />
        <Route exact path="/menukort/dessert" element={<Dessert />} />
        {/* <Route exact path="/menukort/nyheder" element={<Nyheder />} /> */}
        <Route exact path="/menukort/bestillingssedler" element={<Bestilling />} />
        {/* <Route exact path="/" element={<Countdown />} /> */}
      </Routes>
    </div>
  );
}

export default App;
