import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";
import { motion } from "framer-motion";

const menu1 = "79,00 DKK";
const menu2 = "65,00 DKK";
const menu3 = "99,00 DKK";
const menu4 = "69,00 DKK";
const menu5 = "69,00 DKK";
const pommes = "35,00 DKK";
const nuggetspris = "30,00 DKK";

const Menuer = () => {
  return (
    <div>
      <Nav />
      <div className=" relative h-[100vh] gap-5 max-h-screen">
        <motion.p
          initial={{ opacity: 0, y: "-30" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-white text-center text-3xl Bebas mt-[13vh] border-b"
        >
          Menuer
        </motion.p>

        <motion.div className="mt-[2vh] mb-10 overflow-y-auto w-full flex flex-wrap justify-center items-center">
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Item
              img="/Assets/template.png"
              name="Fiskefilet m. Pommes frites"
              desc="inkl. dip"
              desc2="(Menu 1)"
              price={menu1}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Item
              img="/Assets/3-2.jpg"
              name="Pommes frites m."
              desc="6 stk Chicken Nuggets inkl. dip"
              desc2="(Menu 2)"
              price={menu2}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <Item
              img="/Assets/3-4.jpg"
              name="Fish'n Chips m."
              desc="Tartar sauce"
              desc2="(Menu 3)"
              price={menu3}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.5 }}
          >
            <Item
              img="/Assets/3-5.jpg"
              name="Kebabmix"
              desc="Rå løg, cremefaiche dressing/ketchup"
              desc2="(Menu 4)"
              price={menu4}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.5 }}
          >
            <Item
              img="/Assets/template.png"
              name="Pølsemix"
              desc="Rå løg, karry-ketchup/cremefraiche"
              desc2="(Menu 5)"
              price={menu5}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
          >
            <Item
              img="/Assets/pommes2.jpg"
              name="Pommes Frites"
              desc="inkl. dip"
              price={pommes}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: "-30" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 2.5 }}
          >
            <Item
              img="/Assets/3-3.jpg"
              name="Chicken Nuggets"
              desc="5 stk inkl. Dip"
              price={nuggetspris}
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Menuer;
